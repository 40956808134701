.breeder-detail-container {
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-top: 2rem;
  width: 100%;
  max-width:70vw;

}


.breeder-detail-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.breeder-info {
  font-size: 1.3rem;
}


