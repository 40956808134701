.pag-nav{
    position: relative;
    display: flex;
    justify-content: center;
    
}
.page-link {
    color: #198754;
    background-color: white;
    border-color: #198754;
    margin:4px;
    border-radius: 8px;
    text-align: center;
}
.page-link :active{
    border-radius: 8px;
    border-color: #198754;
    border-radius: 8px;
}

.page-link :hover{
    border-radius: 8px;
    border-color: #198754;
}
.pagination li :hover{
    background-color: #198754;
    border-color: #198754;
    color: white;
    border-radius: 8px;
    
}
