.dog-card-title{
    margin: 0;
    font-size: 1.8rem;
}

.dog-about {
    margin-top: 10px;
}

.card-background {
    background-size: cover;
    background-position: center center;
    min-height: 20rem;
    display: flex;
    align-items: relative;

  }

.index-card {
    width: 100%;
    max-width: 30rem;
    overflow: hidden;
}

.card-body {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
}

.details-button {
    position: absolute;
    bottom: .5rem;
    right: .5rem;
}
