.navbar-custom {
	background-color: rgba(0, 0, 0, 0.7);
	font-size: 1.2rem;
	/* padding-right: 1.2rem; */
}

.navbar-custom .navbar-brand {
	text-transform: lowercase;
	font-size: 1.4rem;
	letter-spacing: 0.1rem;
	font-weight: bold;
	color: white;

}

.navbar-custom .navbar-nav .nav-item .nav-link {
	color: white;
	font-weight: bold;
	margin: 0 1rem;
}


.auth {
	font-weight: bold;
}

.navbar-custom .navbar-nav .nav-item .nav-link:hover {
	color: skyblue;
}

.navbar-brand:hover {
	background: linear-gradient(35deg, rgba(221,78,19,1) 0%, rgba(253,128,29,1) 35%, rgba(252,202,69,1) 60%);
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
}

.navbar-toggler {
	background-color: white;
}

.dropdown-menu{
	background-color: black;
	color: white;
}
.nav-link.dropdown-toggle{
	margin-right: 1vw;
}


.nav-link{
	color: white;
}

.nav-link:hover{
	color: skyblue;
}
.furr{
	background: linear-gradient(35deg, rgba(221,78,19,1) 0%, rgba(253,128,29,1) 35%, rgba(252,202,69,1) 60%);
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	/* font-family: 'Cherry Bomb One', cursive; */

}

#paw-logo img{
	padding-right: 10px;
}