body {
  padding-bottom: 3rem;
}

.quiz-btn{
  font-weight: bold;

}

.home-h1 {
  font-size: 5rem;
}

.hero-image {
  position: relative;
  text-align: center;
  background-color: #f8f9fa;
}

.hero-image img {
  width: 100%;
  height: 100%;
}


.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  color: #fff;
  text-align: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  width: 70%;
}


.features {
  margin: 0 2rem;
}


.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}

.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}

.gradient-text {
  background: linear-gradient(35deg, rgba(221, 78, 19, 1) 0%, rgba(253, 128, 29, 1) 35%, rgba(252, 202, 69, 1) 60%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}


.featurette-heading {
  font-size: 50px;
  margin-top: 6rem;

}

.featurette-divider {
  margin: 5rem 0;
}



@media (max-width: 768px) {
  .featurette-heading {
    margin-top: 0rem;
    font-size: 2rem;
  }

  .lead {
    font-size: 1.1rem;
  }

  .home-h1 {
    font-size: 1.55rem;
  }

  .home-p {
    font-size: .75rem;
  }
  .hero-text{
    margin-top: 3rem;
    width: 90%;
  }
  .quiz-btn{
    font-size: .75rem;
  }

}


@media (min-width: 769px) and (max-width: 1200px) {
  .featurette-heading {
    margin-top: 3rem;
    font-size: 2.5rem;
  }

  .home-h1 {
    font-size: 3rem;
  }

  .home-p {
    font-size: 1.5rem;
  }
}


@media (min-width: 1201px) {
  .featurette-heading {
    margin-top: 6rem;
    font-size: 50px;
  }

  .home-h1 {
    font-size: 5rem;
  }

  .home-p {
    font-size: 2rem;
  }
}