.detail-container {
  padding: 20px;
  background-color: #f5f5f5;
  border: 2px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dog-breed-title {
  font-size: 3rem;
}



.dog-about {
  margin-bottom: 20px;
}


.dog-detail-card-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.dog-card-text {
  font-size: 1.3rem;
}

.dog-card {
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2);
  height: 8rem;
  /* width: 25rem; */
  justify-content: center;
  display: flex;
  align-items: center;
  /* margin: 0 auto; */
}



.progress-section {
  margin-top: 2rem;
  margin-left: 1rem;
}

.progress-title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}


.bi-info-circle {
  display: inline-flex;
  font-size: .8rem;
  margin-right: .3rem;

}

.progress-bar-container {
  height: 20px;
  border-radius: 10px;
  background-color: #e0e0e0;
  overflow: hidden;
}




.mini-progress-bars-container {
  display: flex;
  /* margin-left: 1rem; */
}

.mini-progress-bar {
  width: 6rem;
  height: .8rem;
  background-color: #ccc;
  margin-right: 5px;
  margin-top: .5rem;
  border-radius: 5px;
}

.mini-progress-bar.filled {
  background-color: green;
  border-radius: 5px;
}

.button-section {
  display: flex;
  justify-content: space-between;
}

.dog-image img {
  width: 25vw;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.aws-image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .dog-image img {
    width: 50vw;
  }
}