
.breeder-card-fluid {
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.breeder-card-fluid:hover {
  transform: translateY(-5px);
}

.breeder-list-card {
  width: 100%;
  max-width: 30rem;
  padding: 1rem;
}

.breeder-links {
  text-decoration: none;
  color: black;
}


.breeder-card-title {
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}

.breeder-card-title a {
  font-weight: bold;
}

a{
  text-decoration: none;
}

